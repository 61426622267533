import React from "react";
import {
    BaseballOutline,
    BaseballSolid,
    BasketballOutline,
    BasketballSolid,
    EsportOutline,
    EsportSolid,
    FlameOutline,
    FlameSolid,
    AmericanFootballOutline,
    AmericanFootballSolid,
    IceHockeyOutline,
    IceHockeySolid,
    SoccerOutline,
    SoccerSolid,
    TennisOutline,
    TennisSolid
} from "@bettorsignals/assets";
import {CategoryEnum} from "@bettorsignals/api";

export const CATEGORIES = {
    [CategoryEnum.BestSignals]: {
        id: CategoryEnum.BestSignals,
        IconSolid: <FlameSolid />,
        IconOutline: <FlameOutline />,
        name: "Best Signals"
    },
    [CategoryEnum.AmericanFootball]: {
        id: CategoryEnum.AmericanFootball,
        IconSolid: <AmericanFootballSolid />,
        IconOutline: <AmericanFootballOutline />,
        name: "Football" //TODO change category id and whole namespace the across project to be plain "Football"
    },
    [CategoryEnum.Baseball]: {
        id: CategoryEnum.Baseball,
        IconSolid: <BaseballSolid />,
        IconOutline: <BaseballOutline />,
        name: "Baseball"
    },
    [CategoryEnum.Basketball]: {
        id: CategoryEnum.Basketball,
        IconSolid: <BasketballSolid />,
        IconOutline: <BasketballOutline />,
        name: "Basketball"
    },
    [CategoryEnum.IceHockey]: {
        id: CategoryEnum.IceHockey,
        IconSolid: <IceHockeySolid />,
        IconOutline: <IceHockeyOutline />,
        name: "Ice Hockey"
    },
    [CategoryEnum.Soccer]: {
        id: CategoryEnum.Soccer,
        IconSolid: <SoccerSolid />,
        IconOutline: <SoccerOutline />,
        name: "Soccer"
    },
    [CategoryEnum.Tennis]: {
        id: CategoryEnum.Tennis,
        IconSolid: <TennisSolid />,
        IconOutline: <TennisOutline />,
        name: "Tennis"
    },
    [CategoryEnum.ESport]: {
        id: CategoryEnum.ESport,
        IconSolid: <EsportSolid />,
        IconOutline: <EsportOutline />,
        name: "E-Sport"
    }
};
